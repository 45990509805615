import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { DetailsBlog, Blockquote } from "../../components/details.js";
import { NextPrevPosts } from "../../components/blogPost.js";
import thumbnail from "../../images/blog/microsoft-2019/thumbnail.png";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            type
            date
            order
            readTime
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1>{props.pageContext.frontmatter.title}</h1>
    </div>
    <DetailsBlog date={props.pageContext.frontmatter.date} time={props.pageContext.frontmatter.readTime} mdxType="DetailsBlog" />
    <p>{`This past summer, I had the amazing opportunity to intern at Microsoft! During my time there, I had the opportunity to work with some incredible people, learn all about the inner-workings of the company, and even develop an app that is used internally within the company. Reflecting back, I learned three main things during my time there.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a801efe3886114d059512865828d8b0a/21b4d/emilymicrosoft.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADH0lEQVQoz03B61PSAAAA8P0tfelhlgq2jW1sbIg6QE1AOkHxkT3UurS0LNOsrqu01NS6LC3Ny0fZVeRdBlkZkmWRl2eXFaUZmuIDQWAwtvWpu34/oPBIpj47Sb9faihMUe5UQzIEkRPxeFxNU/O7qem75vYHr578YYVFhvOEOCbCe0J8IML5WGExLAA7M8m9ZZrdpbRKk5BhzFJp0iGZJEq8qaqutrK2quTEvl9ud1AQwhzHsmyQ5fxhLsILHM/zggDAZJTGSKbocLkSBvHkaDQFJlLRhHRKbZDS+rLqhktXu6y2txFBWA2wflbw+ENzS/PrLL8aCAO6THmiGgElsUiCSIIRWXKCNupJpQFLNsCJxlhcuyFOVVZzZnJ85OvvuXHnd9vEp96nD8amfj4ZfgHoTAoIi0HIGIjcAkJ4V93p5rb0gqLywpJKY/6h/KJjeQcqTTkFDTVVVkv3N6fV9qb3maX17VjP85e3gJIDGSlpCWJoKyGNjoFwOKfyYBZKKtRJSq2C1iarM0g6jRBhBhExYL4fZr2eVbff5/atza4sfwGM6QqDRp4qh1AweodYvHXj5qhtoEgMx4MICKMSRErJFRhKFhcUO6eHZube/XJ9dK84PV53KBgGKBSmYFCBiDEoBtwBqdSarOw8kkqUQCiOEmmpOpMpb7sIR1X72vrq7Y5H0y77tMs247LN/3EAuSYVTYOULB5F4jAMxXGKlFMERWAkKkuW4kps9/58mYpGtLmNnccfDtaPOjrHv5gnnJYfsyNAx7Vj+l24TBGbpBTVnDp8sLQIIqJoA0qbIEWOOKciqfVmoyHPpNLqzjWXtnQeb++rMA9dHBxutH/oAR4NXLh642hBcWp1da7FfOdy7dnSiowrHcW1bXvq24tu3yvv6jlf31Ledfdk7+Pz/ZZLg69bJr8+9XrnGYYBhkev2x2tVnvji5GWsffd/Q8bhl42WaxNA4N19tEbDke37X3b5ynzzOzr5dUphlni+bDwD8AEV9Z9C771xYB/2eed9/pc3rWFwPoS418J+j0RNsjznPAfnuc5LiIIXJAJ/QVj1bl5AT75rwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Me at Microsoft!",
            "title": "Me at Microsoft!",
            "src": "/static/a801efe3886114d059512865828d8b0a/1cfc2/emilymicrosoft.png",
            "srcSet": ["/static/a801efe3886114d059512865828d8b0a/3684f/emilymicrosoft.png 225w", "/static/a801efe3886114d059512865828d8b0a/fc2a6/emilymicrosoft.png 450w", "/static/a801efe3886114d059512865828d8b0a/1cfc2/emilymicrosoft.png 900w", "/static/a801efe3886114d059512865828d8b0a/21b4d/emilymicrosoft.png 1280w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`1. People make the place amazing`}</h2>
    <p>{`Microsoft is a really cool place to work. As such as large company, of course one would think that it is a great place. But nowadays, people boast the ping pong and foosball tables as all that is needed to make a company’s work culture “great” — instead, it’s the people around you that help to cultivate a really amazing working environment.`}</p>
    <p>{`I remember coming to the office on my first day and one of the most memorable things was my managers, Lia De Cicco-Remu and Ahmed Adel, telling us to read Satya Nadella’s autobiography, `}<strong parentName="p">{`Hit Refresh`}</strong>{` (`}<a href="https://www.amazon.ca/Hit-Refresh-Rediscover-Microsofts-Everyone/dp/0062652508/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1580075928&sr=8-1" className="link" target="_blank" rel="noreferrer">{`Amazon`}</a>{` | `}<a href="https://www.chapters.indigo.ca/en-ca/books/hit-refresh-the-quest-to/9780062959720-item.html?ikwid=hit+refresh&ikwsec=Home&ikwidx=0" className="link" target="_blank" rel="noreferrer">{`Indigo`}</a>{`). They mentioned that it was a foundational part of how Microsoft operates. Intrigued, I took the time to read the book during my commutes and lunch breaks in the following weeks.`}</p>
    <p>{`One of my favourite quotes from the book was the following:`}</p>
    <Blockquote text={'"The next time you are in a meeting, ask the quietest person what they think. Invite everyone into the conversation."'} mdxType="Blockquote" />
– Sateya Nadella
    <br />
    <br />
    <p>{`Personally, sometimes I end up being the quietest in the room, not because I have nothing to say, I just prefer to listen to what others have to say first and often share my thoughts last or not at all if the conversation moves too quickly. But at Microsoft, this changed as often I was asked my opinion at the beginning, if not first. This was a change of pace for me, but welcomed by the fact that now I always felt heard and included in the conversation. There was an aura of inclusivity that I rarely felt elsewhere.`}</p>
    <p>{`Not only was it such an inclusive environment, but everywhere I looked in Microsoft, everyone was learning and doing what they could to improve with growth mindsets. As an intern, this meant that others were willing to help me out when I was confused or stuck. I also got to meet some of Microsoft Canada’s executives and researchers. It was really exciting to learn about their perspectives.`}</p>
    <p>{`The people really made it a lit place where I truly wanted to come to the office everyday despite commuting from over an hour away.`}</p>
    <h2>{`2. Teamwork is Everything`}</h2>
    <p>{`We started off our internship with not knowing each other, but by the end, it felt like we had known each other for a long time.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "743px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/85edbac725365bc789573cf8836b7f91/c84e8/team.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAU+fIuMxcf/EAB0QAAAFBQAAAAAAAAAAAAAAAAACAxESARMjM0H/2gAIAQEAAQUCvuKqSKZUsmxdW2//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwHFYf/EABoQAAMBAAMAAAAAAAAAAAAAAAABAhEhQVH/2gAIAQEABj8CWN6yq3MZ2ymSvVyUf//EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8hlADUrzKIxNr7r0xHB2+5781h+s3BHgw3/9oADAMBAAIAAwAAABDDD//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxDVcZY3/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhEf/aAAgBAgEBPxDgMhjb/8QAHRABAQACAgMBAAAAAAAAAAAAAREAQSExUWGRof/aAAgBAQABPxApXgoAZH5+mdkngacBrzjFidj16xGKmKKymEQ6xODccAWkYaIZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Team at Microsoft",
            "title": "Team at Microsoft",
            "src": "/static/85edbac725365bc789573cf8836b7f91/c84e8/team.jpg",
            "srcSet": ["/static/85edbac725365bc789573cf8836b7f91/863e1/team.jpg 225w", "/static/85edbac725365bc789573cf8836b7f91/20e5d/team.jpg 450w", "/static/85edbac725365bc789573cf8836b7f91/c84e8/team.jpg 743w"],
            "sizes": "(max-width: 743px) 100vw, 743px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <br />
    <p>{`We worked hard together, and we played hard together. During lunch breaks or other slow times, we bonded by playing online chess with each other, or by taking side trips to the Microsoft store. It was always an absolute blast. Not only did we have a lot of fun together, but we learned a lot of cool chess tricks, too!`}</p>
    <p>{`We also quickly formed a system of work where we got to use our strengths, but also learn new skills from each other. This made it easy for us to grow and efficiently finish projects as a team.`}</p>
    <h2>{`3. Passion + Determination = Endless Possibilities`}</h2>
    <p>{`As a group, we all have many passions and our time at Microsoft gave us the opportunity to pursue those passions in a way where we could make an impact on others. With a passion for tech, design, and helping others, I was able to amalgamate those passions into the many projects that we worked on.`}</p>
    <p>{`Some of the projects that we got to work on include an Inventory Management Platform to manage marketing assets for the entirety of Microsoft Canada, as well as experimenting with Power BI, and using AI and computer vision in Microsoft Azure to recognize quantities of objects.`}</p>
    <p>{`Despite not knowing any of the tech stack before beginning the projects, my drive to build something that makes an impact was valuable in pushing me to learn quickly and contribute to my team’s projects. I was able to learn so many tools that I know I will be able to use in the future to do more good for the world.`}</p>
    <div className="row">
  <div className="column">
    <p>
      In particular, I am proud of the work we did for Inventory Management
      Platform App which we got to present at Microsoft Canada’s National Annual
      General Meeting in September of 2019. With the 800 people watching our
      presentation, we got to show off the application which would be in use
      across the nation. It was awesome to see how something I helped build was
      going to be used.
    </p>
  </div>
  <div className="column column-img">
    <img src={thumbnail} alt="My case study for my work at Microsoft" />
    <p className="caption">
      Check out my case study for my work at Microsoft{" "}
      <AnchorLink to="/microsoft" title="Microsoft Case Study" className="link" mdxType="AnchorLink">
        here
      </AnchorLink>
    </p>
  </div>
    </div>
    <p className="centre-hr">--------------------</p>
    <p>{`Overall, my time interning at Microsoft was amazing. As someone who had just graduated from high school, it was eye-opening to see the inside of a tech giant, and be able to create something that will be used by thousands is even more exciting. I want to extend my thanks to everyone at Microsoft for helping me grow during my summer there.`}</p>
    <p>{`Now, looking towards the future after my first internship, I am excited to see what journey I will embark on and learn along the way!`}</p>
    <h4>{`More posts`}</h4>
    <NextPrevPosts projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="NextPrevPosts" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      